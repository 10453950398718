<template>
  <div style="height: inherit">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'SettingsIndex',
}
</script>
